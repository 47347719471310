import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 20px;

  background: #fff;
  border: 0px solid #efefef;
  border-top-width: 3px;

  box-shadow: 1px -2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
`;
//
