import styled, { keyframes } from 'styled-components';
import { parseToRgb } from 'polished';
import { animated } from 'react-spring';
import { FilterContainer } from 'components/Header/styles';

const animatedLogo = keyframes`
from {
  width: 0px;
  filter: blur(2px);
  border-right-width: 0px;
  border-left-width: 0px;
} to {
  /* width: 100%; */
  width: 500px;
  filter: blur(0px);
  /* border-right-width: 3px; */
  /* border-left-width: 3px; */
}`;

const animatedAfterLogo = keyframes`
0%{
  opacity: 0;
  height: 0%;
} 99%{
  height: 77%;
} 100% {
  opacity: 1;
  height: 75%;
}`;

const animatedZeroBorder = keyframes`
0%{

} 99%{
  height: 0%;
} 100% {
  opacity: 0;
}
`;

const animatedFullAfterBorder = keyframes`
from {
  height: 75%;
  right: 50%;
  opacity: 1;
} to {
  height: 0%;
  right: 0;
  opacity: 0;
}`;

const animatedLogoFull = keyframes`
from{
  padding: 0;
  width: 0px;
}
to {
  padding: 0 0 0 15px;
  width: 100%;
}
`;

const appearUpload = keyframes`
from {
  opacity: 0;
  pointer-events: none;
} to {
  opacity: 1;
  pointer-events: all;
}
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoContainer = styled.div`
  transform: translateY(-100px);
  display: flex;
  align-items: center;
  justify-content: center;

  background: #fff;

  width: 100vw;
  height: 200px;

  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  > div {
    position: relative;
    /* background: #fce202; */

    margin: 0 auto;
    width: 500px;
    filter: blur(20px);
    height: 90%;
    display: grid;
    align-items: center;
    grid: auto / 1fr 1fr;
    animation: ${animatedLogo} 1s forwards;

    &:after,
    &:before {
      position: absolute;
      content: '';
      border: 0px solid black;
      height: 100%;
    }

    &:after {
      animation-delay: 1.9s;
    }
    &:before {
      border-left-width: 3px;
      animation: ${animatedZeroBorder} 1s forwards;
      animation-delay: 0.9s;
      left: 0;
    }

    div {
      overflow: hidden;
      margin: auto;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      &:nth-child(1) {
      }
      &:nth-child(2) {
        width: 0px;
        margin: auto 0;
      }
    }
  }
`;

export const LogoShort = styled.div`
  align-items: center;
  margin: 0 auto;
  position: relative;

  overflow: hidden;
  border: 0px solid black;
  color: #4a148c;
  text-shadow: 1px 5px 5px rgba(149, 117, 205, 1);

  &:after {
    content: '';
    opacity: 0;
    height: 0;
    position: absolute;
    right: 0;
    border: 0px solid #332e2e;
    border-right-width: 3px;
    animation: ${animatedAfterLogo} 1s forwards;
    animation-delay: 1.1s;
  }

  p {
    font-size: 100px;
    font-family: 'Gugi', cursive;
  }
`;

export const LogoFull = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 0px;

  overflow: hidden;

  animation: ${animatedLogoFull} 1s forwards;
  animation-delay: 1s;

  color: #4a148c;

  &:after {
    content: '';
    border: 0px solid black;
    border-right-width: 3px;
    position: absolute;
    height: 75%;
    right: 50%;
    height: 45%;

    opacity: 0;
    animation: ${animatedFullAfterBorder} 1s forwards;
    animation-delay: 1s;
  }

  p {
    font-size: 18px;
  }
`;

export const ImportContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  height: 200px;
  width: 100vw;
  max-width: 500px;
`;

export const DropZoneWrapper = styled.div``;

export const DropContainer = styled.div`
  --red: ${parseToRgb('#fff').red};
  --green: ${parseToRgb('#fff').green};
  --blue: ${parseToRgb('#fff').blue};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 460px;
  height: 200px;
  background: rgba(var(--red), var(--green), var(--blue), 1);
  border: 4px dashed #4a148c;
  border-radius: 5px;
  cursor: pointer;

  input {
    display: none;
  }

  svg {
    height: 30px;
    width: 30px;
  }

  p {
    color: #332e2e;
  }
`;

export const FileInfoContainer = styled(animated.div)`
  margin: 10px 0 0 0;
  padding: 0 3px;
  display: flex;
  font-size: 16px;
  color: #464646;
  height: 40px;

  *:not(input, textarea) {
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }

  svg {
    width: 25px;
    height: 25px;

    color: #0e2449;
  }

  p {
    margin: 0 0 0 5px;
  }
`;

export const UploadButton = styled(animated.button)`
  position: absolute;
  top: 50%;
  height: 50px;
  width: 50px;
  transform: translateY(-75%);
  right: -35px;

  border: 3px solid #0e2449;
  background: #0e2449;
  border-radius: 5px;
  transition: box-shadow 0.5s;
  svg {
    color: #fff;
    width: 30px;
    height: 30px;
    transition: color 0.5s;
  }

  &:hover {
    /* rgb(0, 200, 83)
    00C853 */
    box-shadow: 0px 0px 10px 1.5px rgba(0, 200, 83, 1);
    /* background-color: #274069; */
    /* border-color: #fe8f20; */
    svg {
      color: #00c853;
    }
  }
`;

export const HasDataContainer = styled(FilterContainer)`
  z-index: 25;
  position: absolute;
  top: 15px;
  right: 15px;

  margin: 0;

  button {
    & + button {
      margin: 0 0 0 15px;
    }
  }
`;
