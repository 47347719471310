import React, { useCallback } from 'react';
import { FaChartBar, FaSignOutAlt, FaTable } from 'react-icons/fa';

import { useNavigate, useLocation } from 'react-router-dom';

import { useAppData } from 'hooks/appData';

import {
  HeaderContainer,
  FilterContainer,
  Greetings,
  LogoLike,
  LG,
  LS,
  LF,
  EndSession,
  ChildrenContainer,
  ContentContainer,
} from './styles';

const Header: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const { end } = useAppData();

  const navigate = useNavigate();

  const handleNavigateRoot = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const handleNavigation = useCallback(() => {
    navigate(
      pathname.includes('dashboard')
        ? '/charts'
        : pathname.includes('charts')
        ? '/dashboard'
        : pathname,
    );
  }, [navigate, pathname]);
  return (
    <HeaderContainer>
      <LG>
        <button type="button" onClick={handleNavigateRoot}>
          <div>
            <LS>
              <p>AAF</p>
            </LS>
            <LF>
              <p>Aplicação</p>
              <p>Análise</p>
              <p>Financeira</p>
            </LF>
          </div>
        </button>
      </LG>
      <ChildrenContainer>{children}</ChildrenContainer>

      <FilterContainer>
        <button type="button" onClick={handleNavigation}>
          {pathname.includes('dashboard') && <FaChartBar />}
          {pathname.includes('charts') && <FaTable />}
        </button>
      </FilterContainer>
      <EndSession>
        <button type="button" onClick={end} title="Limpar dados">
          <FaSignOutAlt />
        </button>
      </EndSession>
    </HeaderContainer>
  );
};

export default Header;
