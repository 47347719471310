import styled, { css, keyframes } from 'styled-components';
import { animated } from 'react-spring';

interface CategoryProps {
  marked?: boolean;
}

interface ChartProps {
  length: number;
}

const appearLetter = keyframes`
from {
  opacity: 0;
  transform: translateX(500px);
} to {
  opacity: 1;
  transform: translateX(0px);
}
`;

const appearHarold = keyframes`
from {
  opacity: 0;
  transform: translateX(500px);
} to {
  opacity: 1;
  transform: translateX(0px);
}
`;

export const MessageContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 1;

  width: 100vw;
  height: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  h1 {
    position: absolute;
    right: 32%;
    top: 55%;

    opacity: 0;
    transform: translateX(500px);

    animation: ${appearLetter} 1s forwards;
    animation-delay: 3.1s;
  }
  img {
    z-index: -1;
    position: absolute;
    height: 500px;
    bottom: 0;
    right: 0;
    opacity: 0;
    animation: ${appearHarold} 3s forwards;
  }
`;

export const AnalysisContainer = styled.div`
  display: flex;
  flex-grow: 100;
  width: 100vw;
  height: 100%;
  overflow: hidden;

  background: #fff;
  border: 2px solid #efefef;
  border-left-width: 0px;
  border-right-width: 0px;
`;

export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;

  div {
    &:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 0 0 10px;
      height: 40px;
    }
    &:nth-child(2) {
      padding: 5px 10px;
      width: 100vw;
      /* height: 100%; */
      max-height: calc(100vh - 230px);
      overflow-y: scroll;

      display: grid;
      grid: auto / 1fr 1fr;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      row-gap: 10px;

      max-width: 300px;

      z-index: 25;

      -webkit-user-drag: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -khtml-user-select: none;
      user-select: none;
    }
  }
`;

export const ClearContainer = styled(animated.div)`
  button {
    border: 3px solid #c53030;
    padding: 3px 5px;
    border-radius: 5px;
    color: #c53030;

    transition: background-color 0.35s, color 0.35s;
    &:hover {
      background: #c53030;
      color: #fff;
    }
  }
`;

export const CategoryButton = styled.button<CategoryProps>`
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid #1565c0;
  border-radius: 3px;
  transition: background-color 0.5s ease, border-color 0.5s ease;

  p {
    font-size: 16px;
    color: #1565c0;
    font-family: 'Arial', serif;
    font-weight: bold;
    transition: color 0.5s ease;
  }

  &:hover {
    background: #1565c0;
    p {
      color: #fff;
    }
  }

  ${(props) =>
    props.marked &&
    css`
      background: #01579b;
      border-color: #01579b;
      p {
        color: #fff;
      }
    `}
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 100;

  > div {
    &:nth-child(2) {
      flex-grow: 100;
    }
  }
`;

export const MonthSelection = styled(animated.div)`
  margin: 10px 0;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
`;

export const MonthButton = styled.button<CategoryProps>`
  padding: 3px 5px;
  border-radius: 3px;
  border: 2px solid black;
  background: transparent;

  transition: 0.35s ease;

  ${(props) =>
    props.marked &&
    css`
      background: #fff9c4;
    `}
`;

export const ChartExhibition = styled(animated.div)`
  flex-grow: 100;
`;
