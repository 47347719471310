import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    -webkit-tap-highlight-color: transparent;

    font-size: 16px;
    h1{
      font-size: 32px;
    }
    h2{
      font-size: 24px;
    }
    h3{
      font-size: 18.72px;
    }
    h4{
      font-size: 16px;
    }
    h5{
      font-size: 13.28px;
    }
    h6{
      font-size: 10.72px;
    }

  }

  html{

    font-size: 16px;
    background: #bfddee;
    font-family: 'Roboto Slab', serif;
    font-family: 'Arial';
  }
  body{
    /* font-size: 16px; */
  }

  button{
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
`;
