import styled, { css } from 'styled-components';
import { parseToRgb } from 'polished';
import { animated } from 'react-spring';

interface ButtonMonthProps {
  open: boolean;
}

interface CurrencyButtonProps {
  marked: boolean;
}

type DateButtonProps = CurrencyButtonProps;

export const Wrapper = styled.div`
  width: 100vw;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-top: 2px solid #464646;
  border-bottom: 2px solid #464646;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  table-layout: fixed;

  select,
  svg {
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  select {
    position: relative;
    border: 0;
    background: #332e2e;
    color: #fff;
    font-size: 13px;
  }
  /* thead,
  tbody {
    width: 100%;
  } */
  thead {
    background: #332e2e;
    color: #fff;
    font-size: 13px;

    > tr {
      > td {
        cursor: pointer;
        &:nth-child(4) {
          cursor: unset;
        }
      }
    }
  }
  tr {
    height: 25px;

    td {
      position: relative;
      font-size: 13px;
      vertical-align: middle;

      svg {
        margin: 0 0 0 15px;
      }

      &:nth-child(1) {
        width: 125px;
        text-align: center;
      }
      &:nth-child(2) {
        width: 110px;
      }
      &:nth-child(3) {
        width: 150px;
      }
      &:nth-child(5),
      &:nth-child(6) {
        width: 120px;
      }
    }
  }
`;

export const CurrenciesContainer = styled(animated.div)`
  margin: 0 0 10px 0;

  display: flex;

  span + span {
    margin: 0 0 0 15px;
  }
`;

export const CurrencyButton = styled.button<CurrencyButtonProps>`
  /* --bgcolor: #e1bee7; */
  --bgcolor: #d1c4e9;
  --bgRed: ${parseToRgb('#D1C4E9').red};
  --bgGreen: ${parseToRgb('#D1C4E9').green};
  --bgBlue: ${parseToRgb('#D1C4E9').blue};

  /* --fontcolor: #4a148c; */
  --fontcolor: #000;
  --fontRed: ${parseToRgb('#4a148c').red};
  --fontGreen: ${parseToRgb('#4a148c').green};
  --fontBlue: ${parseToRgb('#4a148c').blue};

  --bordercolor: #000;
  --borderRed: ${parseToRgb('#000').red};
  --borderGreen: ${parseToRgb('#000').green};
  --borderBlue: ${parseToRgb('#000').blue};
  /* f9a825 4a148c */
  ${(props) =>
    props.marked &&
    css`
      --bgcolor: #fff9c4;
      --bgRed: ${parseToRgb('#fff9c4').red};
      --bgGreen: ${parseToRgb('#fff9c4').green};
      --bgBlue: ${parseToRgb('#fff9c4').blue};

      /* --fontcolor: #ff6f00; */
      --fontcolor: #000;
      --fontRed: ${parseToRgb('#ff6f00').red};
      --fontGreen: ${parseToRgb('#ff6f00').green};
      --fontBlue: ${parseToRgb('#ff6f00').blue};
    `}

  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  border: 3px solid #332e2e;

  background: var(--bgcolor);
  border: 3px solid var(--bordercolor);
  box-shadow: 1px 0px 5px 1.5px
    rgba(var(--borderRed), var(--borderGreen), var(--borderBlue), 0.5);

  color: var(--fontcolor);

  transition: all 0.35s;
  p {
    font-size: 26px;
    font-weight: bolder;
  }

  &:hover {
    box-shadow: 2px 0px 10px 3px
      rgba(var(--borderRed), var(--borderGreen), var(--borderBlue), 0.5);
  }
  /*
  padding: 2px 5px;
  background: var(--bgcolor);
  border: 2px solid var(--bordercolor);
  border-radius: 3px;
  color: var(--fontcolor);
  box-shadow: 1px 0px 5px 1.5px
    rgba(var(--borderRed), var(--borderGreen), var(--borderBlue), 0.5);

  p {
    font-family: 'Roboto Slab', serif;
  }
  transition: all 0.35s;

  &:hover {
    box-shadow: 2px 0px 10px 3px
      rgba(var(--borderRed), var(--borderGreen), var(--borderBlue), 0.5);
  } */
`;

export const ButtonMonths = styled(animated.button)<ButtonMonthProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px;
  height: 40px;
  width: 40px;
  border-radius: 0 50% 50% 50%;
  border: 3px solid #332e2e;
  border-style: solid;
  transition: border-radius 0.35s, border-style 0.35s;

  background: #f8bbd0;

  svg {
    transition: transform 0.35s;
    height: 27px;
    width: 27px;
    color: #332e2e;
  }

  ${(props) =>
    props.open &&
    css`
      border-radius: 50% 50% 0 50%;
      border-style: solid dotted dotted solid;
      svg {
        transform: rotate(180deg);
      }
    `}
`;
export const DatesContainer = styled(animated.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100vw;
  overflow: hidden;

  > div {
    width: 100%;
    display: grid;
    grid: auto / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;

    column-gap: 10px;
    row-gap: 10px;

    button {
      height: 27px;
    }
  }

  /* overflow: hidden; */
`;

export const DateButton = styled.button<DateButtonProps>`
  padding: 3px 5px;
  border-radius: 3px;
  border: 2px solid black;
  background: transparent;

  transition: 0.35s ease;

  ${(props) =>
    props.marked &&
    css`
      background: #fff9c4;
    `}
`;
