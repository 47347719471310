import React from 'react';

import { Routes as Switch, Route } from 'react-router-dom'; // Switch virou Routes

import * as R from './routes';

const Routes: React.FC = () => {
  // const { user } = useAuth();
  return (
    <Switch>
      {/* Rota privada precisa criar um componente pra fazer o wrap */}
      <Route path="/" element={<R.Main />} />
      <Route element={<R.PrivateRoute />}>
        <Route path="/dashboard" element={<R.Dashboard />} />
      </Route>
      <Route element={<R.PrivateRoute />}>
        <Route path="/charts" element={<R.Charts />} />
      </Route>
      <Route element={<R.PrivateRoute />}>
        <Route path="*" element={<R.Page404 />} />
      </Route>
    </Switch>
  );
};

export default Routes;
