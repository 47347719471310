import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  FaCloudUploadAlt,
  FaDownload,
  FaFileUpload,
  FaFileDownload,
  FaFileCsv,
  FaShare,
  FaSignInAlt,
  FaChartBar,
  FaTable,
} from 'react-icons/fa';

import { useAppData } from 'hooks/appData';

import { useNavigate } from 'react-router-dom';
import { useSpring } from 'react-spring';

import api from 'api';

import {
  Container,
  LogoContainer,
  LogoShort,
  LogoFull,
  ImportContainer,
  DropZoneWrapper,
  DropContainer,
  FileInfoContainer,
  UploadButton,
  HasDataContainer,
} from './styles';

const Main: React.FC = () => {
  const { initialize, appData } = useAppData();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const [fileList, setFileList] = useState<FileList>({} as FileList);

  useEffect(() => {
    //
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleFiles = useCallback((files: FileList) => {
    const filename = files[0].name;

    if (filename.substr(-3) !== 'csv') {
      setFileList({} as FileList);
      alert('Arquivo não suportado');
      return false;
    }

    return true;
  }, []);

  const fileSize = (size: number): string => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / k ** i).toFixed(2))} ${sizes[i]}`;
  };

  const handleFileDrop = useCallback(
    (e) => {
      e.preventDefault();
      const { files } = e.dataTransfer;
      setFileList(files);
      if (files.length > 0) {
        handleFiles(files);
      }
    },
    [handleFiles],
  );
  const handleClick = useCallback(() => {
    const input = inputRef.current;

    if (input) {
      input.value = '';
      input.click();
    }
  }, []);
  const handleFileSelected = useCallback(() => {
    const input = inputRef.current;

    if (input && input.files) {
      setFileList(input.files);
      handleFiles(input.files);
    }
  }, [handleFiles]);

  const handleUpload = useCallback(async () => {
    try {
      localStorage.removeItem(`${process.env.REACT_APP_DATA}`);
      const send = new FormData();
      send.append('file', fileList[0]);

      const response = await api.post('/app/handleCSV.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      initialize(response.data);
      navigate('/dashboard');
    } catch (err) {
      alert('Falha na requisição.\nTente novamente.');
    }
  }, [fileList, initialize, navigate]);

  const handleNavigationTable = useCallback(() => {
    navigate('/dashboard');
  }, [navigate]);
  const handleNavigationCharts = useCallback(() => {
    navigate('/charts');
  }, [navigate]);
  const appearButton = useSpring({
    opacity: fileList.length > 0 ? 1 : 0,
    pointerEvents: (fileList.length > 0 ? 'all' : 'none') as any,
  });

  const appearHasData = useSpring({
    opacity: appData ? 1 : 0,
    pointerEvents: (appData ? 'all' : 'none') as any,
  });

  return (
    <Container>
      <HasDataContainer style={appearHasData}>
        <button onClick={handleNavigationCharts} type="button">
          <FaChartBar />
        </button>
        <button onClick={handleNavigationTable} type="button">
          <FaTable />
        </button>
      </HasDataContainer>

      {/* <Loading isLoading={loading} /> */}
      <LogoContainer>
        <div>
          <LogoShort>
            <p>AAF</p>
          </LogoShort>
          <LogoFull>
            <p>Aplicação</p>
            <p>Análise</p>
            <p>Financeira</p>
          </LogoFull>
        </div>
      </LogoContainer>
      <ImportContainer>
        <DropZoneWrapper>
          <DropContainer
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleFileDrop}
            onClick={handleClick}
          >
            <input ref={inputRef} type="file" onChange={handleFileSelected} />
            <FaDownload />
            <p>Arraste o arquivo aqui ou clique para importar</p>
          </DropContainer>
          <FileInfoContainer>
            {fileList.length > 0 && (
              <>
                <FaFileCsv />
                <p>{fileList[0].name}</p>
                <p>({fileSize(fileList[0].size)})</p>
              </>
            )}
          </FileInfoContainer>
        </DropZoneWrapper>

        <UploadButton type="button" style={appearButton} onClick={handleUpload}>
          <FaShare />
        </UploadButton>
      </ImportContainer>

      {/* <ImportContainer>
        <FaFileUpload />
      </ImportContainer> */}
    </Container>
  );
};

export default Main;
