export interface CreateArrayProps {
  [key: string]: string | number | boolean;
}

export interface ArrayProps {
  id: string;
  label: string;
}

export function removeDups(
  array: CreateArrayProps[],
  dupkey: string,
): CreateArrayProps[] {
  return array.filter(
    (item, index) =>
      array.map((ff) => ff[dupkey]).indexOf(item[dupkey]) === index,
  );
}

export function createArray(
  array: CreateArrayProps[],
  idKey: string,
  labelKey: string,
): ArrayProps[] {
  return array.map((item) => ({
    id: item[idKey].toString(),
    label: item[labelKey].toString(),
  }));
}
