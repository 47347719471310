import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppProvider from 'hooks';
import Routes from './routes';
import GlobalStyle from './styles';

const App: React.FC = () => {
  return (
    <BrowserRouter basename={process.env.REACT_APP_DEFAULT_DIR}>
      <GlobalStyle />

      <AppProvider>
        <Routes />
      </AppProvider>
    </BrowserRouter>
  );
};

export default App;
