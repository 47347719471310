import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';

import { useNavigate } from 'react-router-dom';

import { ArrayProps } from 'utils/handleArray';
import { removeDups, createArray } from 'utils/handleArray';
import { removeAcentoAndLower } from 'utils/handleText';
import { handleTimeZone } from 'utils/formatDate';
import { MonthListProps, monthList } from 'utils/monthList';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export interface AppDataProps {
  uuid: string;
  date: string;
  month: number;
  year: number;
  gettime: number;
  value: number;
  status: string;
  money: string;
  moneyLabel: string;
  category: string;
  detail: string;
  created?: string;
  updated?: string;
  origem: string;
}

export interface LocalStorageProps {
  appData?: string;
  currencies?: string;
  types?: string;
  stat?: string;
  curr?: string;
  orig?: string;
  dates?: string;
}

interface CurrProps {
  total: number;
  paper: number;
  virtual: number;
  totE: number;
  totP: number;
}

interface AppContextData {
  appData: AppDataProps[];
  dates: ArrayProps[];
  currency: string;
  currencies: ArrayProps[];
  updateCurrency(money: string): void;
  handleDatefy(date?: string): Date;
  types: ArrayProps[];
  stat: ArrayProps[];
  orig: ArrayProps[];
  initialize(array: []): void;
  end(): void;
  total(currency: string, array?: AppDataProps[]): CurrProps;
}

interface AppState {
  appData: AppDataProps[];
  dates: ArrayProps[];
  currency: string;
  currencies: ArrayProps[];
  types: ArrayProps[];
  stat: ArrayProps[];
  orig: ArrayProps[];
}

const AppContext = createContext<AppContextData>({} as AppContextData);

const AppDataProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [stateData, setStateData] = useState<AppState>(() => {
    const {
      appData,
      currencies,
      types,
      stat,
      curr,
      orig,
      dates,
    }: LocalStorageProps = getLocalStorage(`${process.env.REACT_APP_DATA}`);

    if (appData && currencies && types && stat && orig && dates) {
      return {
        appData: JSON.parse(appData),
        currency: curr || 'all',
        currencies: JSON.parse(currencies),
        types: JSON.parse(types),
        stat: JSON.parse(stat),
        orig: JSON.parse(orig),
        dates: JSON.parse(dates),
      };
    }
    return {} as AppState;
  });

  const handleDatefy = useCallback((date?: string): Date => {
    if (!date) {
      return handleTimeZone(new Date());
    }

    const dated = date.split('/');
    return handleTimeZone(
      new Date(
        parseInt(`20${dated[1]}`, 10),
        monthList[
          monthList.findIndex(
            (item: MonthListProps) => item.short.toLowerCase() === dated[0],
          )
        ].value,
        1,
      ),
    );
  }, []);

  const updateCurrency = useCallback((money: string) => {
    setLocalStorage(`${process.env.REACT_APP_DATA}`, {
      curr: money,
    });

    setStateData((state) => ({ ...state, currency: money }));
  }, []);

  const initialize = useCallback(async (array: []) => {
    if (array.length === 0) {
      return;
    }

    const appData = array
      .map((item: AppDataProps) => ({
        ...item,
        month: handleTimeZone(new Date(item.date)).getMonth(),
        year: handleTimeZone(new Date(item.date)).getFullYear(),
        gettime: handleTimeZone(new Date(item.date)).getTime(),
      }))
      .sort((x: AppDataProps, y: AppDataProps) =>
        y.gettime > x.gettime ? 1 : x.gettime > y.gettime ? -1 : 0,
      );

    const dates = createArray(
      removeDups(
        array.map((item: AppDataProps) => {
          return {
            id: `${format(new Date(item.date), 'MMM/yy', {
              locale: ptBR,
            })}`,
            label: `${format(new Date(item.date), 'MMM/yy', {
              locale: ptBR,
            })}`,
          };
        }),
        'label',
      ),
      'id',
      'label',
    );

    const types = createArray(
      removeDups(array, 'category'),
      'category',
      'category',
    ).sort((x: ArrayProps, y: ArrayProps) =>
      removeAcentoAndLower(x.id) > removeAcentoAndLower(y.id)
        ? 1
        : removeAcentoAndLower(y.id) > removeAcentoAndLower(x.id)
        ? -1
        : 0,
    );

    const currencies = createArray(
      removeDups(array, 'money'),
      'money',
      'moneyLabel',
    ).sort((x: ArrayProps, y: ArrayProps) =>
      x.id > y.label ? 1 : y.label > x.label ? -1 : 0,
    );

    const stat = createArray(
      removeDups(array, 'status'),
      'status',
      'status',
    ).sort((x: ArrayProps, y: ArrayProps) =>
      x.id > y.id ? 1 : y.id > x.id ? -1 : 0,
    );

    const orig = createArray(
      removeDups(array, 'origem'),
      'origem',
      'origem',
    ).sort((x: ArrayProps, y: ArrayProps) =>
      x.label > y.label ? 1 : y.label > x.label ? -1 : 0,
    );
    setStateData({
      appData,
      dates,
      currencies:
        currencies.length > 1
          ? [{ id: 'all', label: 'Todas' }, ...currencies]
          : currencies,
      types,
      stat,
      currency: currencies.length > 1 ? 'all' : currencies[0].id,
      orig,
    });

    const { curr }: LocalStorageProps = getLocalStorage(
      `${process.env.REACT_APP_DATA}`,
    );

    setLocalStorage(`${process.env.REACT_APP_DATA}`, {
      appData: JSON.stringify(appData),
      dates: JSON.stringify(dates),
      currencies: JSON.stringify(
        currencies.length > 1
          ? [{ id: 'all', label: 'Todas' }, ...currencies]
          : currencies,
      ),
      types: JSON.stringify(types),
      stat: JSON.stringify(stat),
      orig: JSON.stringify(orig),
      curr: curr || (currencies.length > 1 ? 'all' : currencies[0].id),
    });
  }, []);

  const end = useCallback(() => {
    localStorage.removeItem(`${process.env.REACT_APP_DATA}`);
    setStateData({} as AppState);
    navigate('/');
  }, [navigate]);

  const total = useCallback(
    (identifier: string, array?: []) => {
      const base = array || stateData.appData;

      const tot = base.reduce(
        (accum, { money, value, status }: AppDataProps) => {
          return money === identifier
            ? ['Ok', 'Pendente'].indexOf(status) > -1
              ? accum + value
              : accum
            : accum;
        },
        0,
      );

      const paper = base.reduce(
        (accum, { money, value, status, origem }: AppDataProps) => {
          return money === identifier
            ? ['Ok', 'Pendente'].indexOf(status) > -1
              ? origem === 'Dinheiro'
                ? accum + value
                : accum
              : accum
            : accum;
        },
        0,
      );

      const virtual = base.reduce(
        (accum, { money, value, status, origem }: AppDataProps) => {
          return money === identifier
            ? ['Ok', 'Pendente'].indexOf(status) > -1
              ? origem !== 'Dinheiro'
                ? accum + value
                : accum
              : accum
            : accum;
        },
        0,
      );

      const totEstimado = base.reduce(
        (accum, { money, value, status, origem }: AppDataProps) => {
          return money === identifier
            ? ['Estimado'].indexOf(status) > -1
              ? accum + value
              : accum
            : accum;
        },
        0,
      );

      const totPendente = base.reduce(
        (accum, { money, value, status, origem }: AppDataProps) => {
          return money === identifier
            ? ['Pendente'].indexOf(status) > -1
              ? accum + value
              : accum
            : accum;
        },
        0,
      );

      return {
        total: tot,
        paper,
        virtual,
        totE: totEstimado,
        totP: totPendente,
      } as CurrProps;
    },
    [stateData.appData],
  );

  const memoizedProvider = useMemo(
    () => ({
      appData: stateData.appData,
      dates: stateData.dates,
      currency: stateData.currency,
      currencies: stateData.currencies,
      types: stateData.types,
      stat: stateData.stat,
      orig: stateData.orig,
      updateCurrency,
      handleDatefy,
      end,
      initialize,
      total,
    }),
    [
      stateData.appData,
      stateData.dates,
      stateData.currency,
      stateData.currencies,
      stateData.types,
      stateData.stat,
      stateData.orig,
      updateCurrency,
      handleDatefy,
      end,
      initialize,
      total,
    ],
  );

  return (
    <AppContext.Provider value={memoizedProvider}>
      {children}
    </AppContext.Provider>
  );
};

function useAppData(): AppContextData {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppData must be used within an AppDataProvider');
  }

  return context;
}
export { useAppData, AppDataProvider };
