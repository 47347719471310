import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  MouseEvent,
} from 'react';

import Header from 'components/Header';
import { TotalContainer } from 'components/Header/styles';
import Footer from 'components/Footer';

import { FaAngleDoubleDown, FaSort } from 'react-icons/fa';
import { formatDate, handleTimeZone } from 'utils/formatDate';

import { useNavigate } from 'react-router-dom';

import { Container, Content } from 'styles/wrappers';

import { removeAcentoAndLower } from 'utils/handleText';

import { getLocalStorage, setLocalStorage } from 'utils/handleLocalStorage';
import { MonthListProps, monthList } from 'utils/monthList';

import { AppDataProps, useAppData } from 'hooks/appData';
import { useSpring } from 'react-spring';
import {
  Wrapper,
  Table,
  CurrenciesContainer,
  CurrencyButton,
  DatesContainer,
  ButtonMonths,
  DateButton,
} from './styles';

interface LocalStorageProps {
  data?: string;
  curr?: string;
}

interface FilterProps {
  hasOrig: string;
  hasStat: string;
  hasType: string;
  hasDate: string;
}

const Main: React.FC = () => {
  const {
    appData,
    dates,
    currencies,
    currency,
    types,
    stat,
    orig,
    total,
    end,
    updateCurrency,
    handleDatefy,
  } = useAppData();

  const selectDateRef = useRef<HTMLSelectElement>(null);
  const selectTypeRef = useRef<HTMLSelectElement>(null);
  const selectStatRef = useRef<HTMLSelectElement>(null);
  const selectOrigRef = useRef<HTMLSelectElement>(null);
  const [rawList, setRawList] = useState(appData);
  const [list, setList] = useState(appData);

  const [typeSorted, setTypeSorted] = useState('');
  const [statSorted, setStatSorted] = useState('');
  const [origSorted, setOrigSorted] = useState('');
  const [dateSorted, setDateSorted] = useState('');
  const [costSorted, setCostSorted] = useState('');
  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  const [isOpen, setIsOpen] = useState(false);

  const handleFilters = useCallback((): FilterProps => {
    const refO = selectOrigRef.current;
    const refS = selectStatRef.current;
    const refT = selectTypeRef.current;
    const refD = selectDateRef.current;

    if (refO && refS && refT && refD) {
      return {
        hasOrig: refO.options[refO.selectedIndex].value || '',
        hasStat: refS.options[refS.selectedIndex].value || '',
        hasType: refT.options[refT.selectedIndex].value || '',
        hasDate: refD.options[refD.selectedIndex].value || '',
      } as FilterProps;
    }
    end();
    return {} as FilterProps;
  }, [end]);

  useEffect(() => {
    setList(() => {
      return currency === 'all'
        ? appData
        : appData.filter((item: AppDataProps) => item.money === currency);
    });
  }, [appData, currency]);

  const handleDate = useCallback(() => {
    const select = selectDateRef.current;

    if (!select) {
      return;
    }

    setDateSorted('');
    setSelectedDates([]);
    const selected = select.options[select.selectedIndex].value;
    const { hasOrig, hasStat, hasType } = handleFilters();

    setList(
      rawList
        .filter((item) =>
          selected !== ''
            ? item.month === handleDatefy(selected).getMonth() &&
              item.year === handleDatefy(selected).getFullYear()
            : item,
        )
        .filter((item) =>
          hasType.length > 0 ? item.category === hasType : item,
        )
        .filter((item) => (hasOrig.length > 0 ? item.origem === hasOrig : item))
        .filter((item) => (hasStat.length > 0 ? item.status === hasStat : item))
        .filter((item) =>
          currency !== 'all' ? item.money === currency : item,
        ),
    );
  }, [currency, handleDatefy, handleFilters, rawList]);

  const handleType = useCallback(() => {
    const select = selectTypeRef.current;

    if (!select) {
      return;
    }
    setTypeSorted('');
    const selected = select.options[select.selectedIndex].value;

    const { hasOrig, hasStat, hasDate } = handleFilters();
    setList(
      rawList
        .filter((item) => (selected !== '' ? item.category === selected : item))
        .filter((item) =>
          hasDate.length > 0
            ? item.month === handleDatefy(hasDate).getMonth() &&
              item.year === handleDatefy(hasDate).getFullYear()
            : item,
        )
        .filter((item) => (currency !== 'all' ? item.money === currency : item))
        .filter((item) => (hasOrig.length > 0 ? item.origem === hasOrig : item))
        .filter((item) =>
          hasStat.length > 0 ? item.status === hasStat : item,
        ),
    );
  }, [handleFilters, rawList, handleDatefy, currency]);

  const handleStatus = useCallback(() => {
    const select = selectStatRef.current;

    if (!select) {
      return;
    }
    setStatSorted('');
    const selected = select.options[select.selectedIndex].value;
    const { hasOrig, hasType, hasDate } = handleFilters();
    setList(
      rawList
        .filter((item) => (selected !== '' ? item.status === selected : item))
        .filter((item) =>
          hasDate.length > 0
            ? item.month === handleDatefy(hasDate).getMonth() &&
              item.year === handleDatefy(hasDate).getFullYear()
            : item,
        )
        .filter((item) => (currency !== 'all' ? item.money === currency : item))
        .filter((item) => (hasOrig.length > 0 ? item.origem === hasOrig : item))
        .filter((item) =>
          hasType.length > 0 ? item.category === hasType : item,
        ),
    );
  }, [handleFilters, rawList, handleDatefy, currency]);

  const handleOrig = useCallback(() => {
    const select = selectOrigRef.current;

    if (!select) {
      return;
    }
    setOrigSorted('');
    const selected = select.options[select.selectedIndex].value;
    const { hasType, hasStat, hasDate } = handleFilters();
    setList(
      rawList
        .filter((item) => (selected !== '' ? item.origem === selected : item))
        .filter((item) =>
          hasDate.length > 0
            ? item.month === handleDatefy(hasDate).getMonth() &&
              item.year === handleDatefy(hasDate).getFullYear()
            : item,
        )
        .filter((item) => (currency !== 'all' ? item.money === currency : item))
        .filter((item) =>
          hasType.length > 0 ? item.category === hasType : item,
        )
        .filter((item) =>
          hasStat.length > 0 ? item.status === hasStat : item,
        ),
    );
  }, [currency, handleDatefy, handleFilters, rawList]);

  const handleSortType = useCallback(() => {
    setList((state) => {
      const isDesc = ['', 'asc'].indexOf(typeSorted) < 0;
      return state.sort((x, y) =>
        removeAcentoAndLower(isDesc ? y.category : x.category) >
        removeAcentoAndLower(isDesc ? x.category : y.category)
          ? 1
          : removeAcentoAndLower(isDesc ? x.category : y.category) >
            removeAcentoAndLower(isDesc ? y.category : x.category)
          ? -1
          : 0,
      );
    });
    setTypeSorted(['', 'asc'].indexOf(typeSorted) > -1 ? 'desc' : 'asc');
  }, [typeSorted]);

  const handleSortStat = useCallback(() => {
    setList((state) => {
      const isDesc = ['', 'asc'].indexOf(statSorted) < 0;
      return state.sort((x, y) =>
        (isDesc ? y.status : x.status) > (isDesc ? x.status : y.status)
          ? 1
          : (isDesc ? x.status : y.status) > (isDesc ? y.status : x.status)
          ? -1
          : 0,
      );
    });
    setStatSorted(['', 'asc'].indexOf(statSorted) > -1 ? 'desc' : 'asc');
  }, [statSorted]);

  const handleSortOrig = useCallback(() => {
    setList((state) => {
      const isDesc = ['', 'asc'].indexOf(origSorted) < 0;
      return state.sort((x, y) =>
        (isDesc ? y.origem : x.origem) > (isDesc ? x.origem : y.origem)
          ? 1
          : (isDesc ? x.origem : y.origem) > (isDesc ? y.origem : x.origem)
          ? -1
          : 0,
      );
    });
    setOrigSorted(['', 'asc'].indexOf(origSorted) > -1 ? 'desc' : 'asc');
  }, [origSorted]);

  const handleSortDate = useCallback(() => {
    setList((state) => {
      const isDesc = ['', 'asc'].indexOf(dateSorted) < 0;
      return state.sort((x, y) =>
        new Date(isDesc ? y.date : x.date).getTime() >
        new Date(isDesc ? x.date : y.date).getTime()
          ? 1
          : new Date(isDesc ? x.date : y.date).getTime() >
            new Date(isDesc ? y.date : x.date).getTime()
          ? -1
          : 0,
      );
    });

    setDateSorted(['', 'asc'].indexOf(dateSorted) > -1 ? 'desc' : 'asc');
  }, [dateSorted]);

  const handleSortCost = useCallback(() => {
    setList((state) => {
      const isDesc = ['', 'asc'].indexOf(costSorted) < 0;

      return state.sort((x, y) =>
        (isDesc ? x.value : y.value) > (isDesc ? y.value : x.value)
          ? 1
          : (isDesc ? y.value : x.value) > (isDesc ? x.value : y.value)
          ? -1
          : 0,
      );
    });

    setCostSorted(['', 'asc'].indexOf(costSorted) > -1 ? 'desc' : 'asc');
  }, [costSorted]);

  const handleCurrency = useCallback(
    (id) => {
      updateCurrency(id);
      const sD = selectDateRef.current;
      const sT = selectTypeRef.current;
      const sS = selectStatRef.current;
      const sO = selectOrigRef.current;
      if (sD) {
        sD.options[0].selected = true;
      }
      if (sT) {
        sT.options[0].selected = true;
      }
      if (sS) {
        sS.options[0].selected = true;
      }
      if (sO) {
        sO.options[0].selected = true;
      }
      setLocalStorage(`${process.env.REACT_APP_DATA}`, {
        curr: id,
      });

      setCostSorted('');
      setDateSorted('');
      setTypeSorted('');
      setStatSorted('');
      setOrigSorted('');
      setSelectedDates([]);

      setList(() => {
        return id === 'all'
          ? rawList
          : rawList.filter((item) => item.money === id);
      });
    },
    [rawList, updateCurrency],
  );

  const handleOpenDates = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  const handleSelectDates = useCallback(
    (id: string) => {
      setDateSorted('');
      const sD = selectDateRef.current;
      if (sD) {
        sD.options[0].selected = true;
      }

      const index = selectedDates.findIndex((item) => item === id);
      const ordered =
        index > -1
          ? selectedDates.filter((item) => item !== id)
          : [...selectedDates, id].sort((x, y) =>
              handleDatefy(x).getTime() > handleDatefy(y).getTime()
                ? 1
                : handleDatefy(y).getTime() > handleDatefy(x).getTime()
                ? -1
                : 0,
            );

      setSelectedDates(ordered);
      const orderedMonths = ordered.map((orderedItem) =>
        handleDatefy(orderedItem).getMonth(),
      );
      const orderedYears = ordered.map((orderedItem) =>
        handleDatefy(orderedItem).getFullYear(),
      );

      const { hasOrig, hasStat, hasType } = handleFilters();

      setList(
        rawList
          .filter((item) =>
            ordered.length === 0
              ? item
              : orderedMonths.includes(item.month) &&
                orderedYears.includes(item.year),
          )
          .filter((item) =>
            hasType.length > 0 ? item.category === hasType : item,
          )
          .filter((item) =>
            hasOrig.length > 0 ? item.origem === hasOrig : item,
          )
          .filter((item) =>
            hasStat.length > 0 ? item.status === hasStat : item,
          )
          .filter((item) =>
            currency !== 'all' ? item.money === currency : item,
          ),
      );
    },
    [currency, handleDatefy, handleFilters, rawList, selectedDates],
  );

  const styledDatesContainer = useSpring({
    opacity: isOpen ? 1 : 0,
    height: isOpen
      ? `${
          Math.ceil(dates.length / 12) * 27 +
          (Math.ceil(dates.length / 12) - 1) * 10
        }px`
      : '0px',
    margin: isOpen ? '10px 0' : '0px 0px',
    pointerEvents: (isOpen ? 'all' : 'none') as any,
  });

  return (
    <Container>
      <Header>
        <TotalContainer>
          {currencies
            .filter((item) => item.id !== 'all')
            .map((item) =>
              item.id === currency || currency === 'all' ? (
                <div key={item.id}>
                  <span>
                    <strong>
                      {item.label}:&nbsp;
                      <strong>
                        {total(`${item.id}`, list).total.toFixed(2)}
                      </strong>
                    </strong>
                  </span>
                  <span>
                    <p>
                      Virtual:&nbsp;
                      <strong>
                        {item.id}&nbsp;
                        {total(`${item.id}`, list).virtual.toFixed(2)}
                      </strong>
                    </p>
                  </span>

                  <span>
                    <p>
                      Papel:&nbsp;
                      <strong>
                        {item.id}&nbsp;
                        {total(`${item.id}`, list).paper.toFixed(2)}
                      </strong>
                    </p>
                  </span>

                  <span>
                    <p>
                      Estimado:&nbsp;
                      <strong>
                        {item.id}&nbsp;
                        {total(`${item.id}`, list).totE.toFixed(2)}
                      </strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      Pendente:&nbsp;
                      <strong>
                        {item.id}&nbsp;
                        {total(`${item.id}`, list).totP.toFixed(2)}
                      </strong>
                    </p>
                  </span>
                </div>
              ) : null,
            )}
        </TotalContainer>
      </Header>
      <Content>
        {dates.length > 1 && (
          <ButtonMonths onClick={handleOpenDates} open={isOpen}>
            <FaAngleDoubleDown />
          </ButtonMonths>
        )}
        <CurrenciesContainer>
          {currencies.map((item) => (
            <span key={item.id}>
              <CurrencyButton
                type="button"
                marked={item.id === currency}
                disabled={item.id === currency}
                onClick={() => handleCurrency(item.id)}
              >
                <p>{item.id === 'all' ? 'T' : item.id.replace(/R/g, '')}</p>
              </CurrencyButton>
            </span>
          ))}
        </CurrenciesContainer>
        {dates.length > 1 && (
          <DatesContainer style={styledDatesContainer}>
            <div>
              {dates
                .map((item) => (
                  <span key={item.id}>
                    <DateButton
                      type="button"
                      onClick={() => handleSelectDates(item.id)}
                      marked={selectedDates.includes(item.id)}
                    >
                      <p>{item.label}</p>
                    </DateButton>
                  </span>
                ))
                .reverse()}
            </div>
          </DatesContainer>
        )}

        <Wrapper>
          <Table>
            <thead>
              <tr>
                <td
                  style={{
                    textAlign: 'left',
                    padding: '0 0 0 5px',
                    cursor: 'pointer',
                  }}
                  onClick={handleSortDate}
                >
                  <select
                    id="date"
                    onChange={handleDate}
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    ref={selectDateRef}
                  >
                    <option value="">Data</option>
                    {dates.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <FaSort />
                </td>
                <td onClick={handleSortCost}>
                  Valor <FaSort />
                </td>

                <td
                  onClick={handleSortType}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <select
                    id="type"
                    onChange={handleType}
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                    ref={selectTypeRef}
                  >
                    <option value="">Tipo</option>
                    {types.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <FaSort />
                </td>
                <td>Detalhe</td>
                <td onClick={handleSortStat}>
                  <select
                    id="status"
                    ref={selectStatRef}
                    onChange={handleStatus}
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                  >
                    <option value="">Status</option>
                    {stat.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <FaSort />
                </td>
                <td onClick={handleSortOrig}>
                  <select
                    id="orig"
                    ref={selectOrigRef}
                    onChange={handleOrig}
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                  >
                    <option value="">Origem</option>
                    {orig.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <FaSort />
                </td>
              </tr>
            </thead>
            <tbody>
              {list.length > 0 ? (
                <>
                  {list.map((item, index) => (
                    <React.Fragment key={item.uuid}>
                      <tr
                        style={{
                          background:
                            index % 2 === 0
                              ? item.status === 'Ok'
                                ? '#e6e6e6'
                                : item.status === 'Estimado'
                                ? '#fce4ec'
                                : '#e8f5e9'
                              : item.status === 'Ok'
                              ? '#fff'
                              : item.status === 'Estimado'
                              ? '#fad1df'
                              : '#cae8cc',
                        }}
                      >
                        <td>{formatDate(item.date)}</td>
                        <td
                          style={{
                            color:
                              item.status !== 'Ok'
                                ? '#332e2e'
                                : item.value < 0
                                ? '#EC407A'
                                : '#3949AB',
                          }}
                        >
                          {item.money} {item.value.toFixed(2)}
                        </td>

                        <td>{item.category}</td>
                        <td>{item.detail}</td>
                        <td>{item.status}</td>
                        <td>{item.origem}</td>
                      </tr>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <tr
                  style={{
                    background: '#fff',
                  }}
                >
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>Sem registros para o filtro selecionado.</td>

                  {/* <td>{item.where}</td> */}
                </tr>
              )}
            </tbody>
          </Table>
        </Wrapper>
      </Content>
      <Footer />
    </Container>
  );
};

export default Main;
