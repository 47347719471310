import { format, isValid } from 'date-fns';
import { parseISO } from 'date-fns/esm';

import { addMinutes, subMinutes } from 'date-fns/esm';

export function formatDate(date?: string): string {
  if (date) {
    if (isValid(new Date(date))) {
      return format(new Date(parseISO(date)), 'dd/MM/yyyy');
    }

    return `${date.substring(8)}/${date.substring(5, 7)}/${date.substring(
      0,
      4,
    )}`;
  }

  return format(new Date(), 'dd/MM/yyyy');
  // if(typeof date === 'string'){
  //   return
  // }

  // return '';
}

export function handleTimeZone(date: string | Date): Date {
  let changeable = typeof date === 'string' ? new Date(date) : date;

  if (changeable.getTimezoneOffset() < 0) {
    changeable = subMinutes(changeable, changeable.getTimezoneOffset() * -1);
  } else {
    changeable = addMinutes(changeable, changeable.getTimezoneOffset());
  }

  return changeable;
}
