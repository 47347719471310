import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 15px 0 0px 0;
  min-height: calc(100vh - 120px);

  overflow-x: hidden;
`;
