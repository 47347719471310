import styled, { keyframes } from 'styled-components';
import { animated } from 'react-spring';

export const HeaderContainer = styled.div`
  position: sticky;
  /* top: -1px; */
  top: 0px;
  display: flex;
  z-index: 2;

  width: 100vw;
  height: 100px;

  background: #fff;
  border: 0px solid #efefef;
  border-bottom-width: 3px;

  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);
  align-items: center;
  /* justify-content: center; */

  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  > span {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    filter: blur(1px);
    width: 100%;
  }

  &:hover {
    div:nth-child(1) {
      h1 {
        &:nth-child(2) {
          /* &:hover { */
          &::before {
            opacity: 1;
            transform: translateX(50%);
            width: 50%;
          }
          /* } */
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
  }
`;

export const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

export const ContentContainer = styled(ChildrenContainer)``;

export const Greetings = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  transition: opacity 0.35s;
  h1 {
    &:nth-child(2) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        border-bottom: 5px solid #fe8f20;
        bottom: 0;
        left: 0;
        border-radius: 5px;
        width: 0%;
        opacity: 0;
        transform: translateX(-50%);
        transition: opacity 0.35s, transform 0.5s, width 0.5s;
      }

      /* &:hover {
        &::before {
          opacity: 1;
          transform: translateX(50%);
          width: 50%;
        }
      } */
    }
  }
`;

export const FilterContainer = styled(animated.div)`
  display: flex;
  margin: 0 35px 0 0;
  /* position: absolute; */
  /* right: 15px;
  bottom: 50%;
  transform: translateY(50%); */

  button {
    border: 3px solid #ab47bc;
    background: #ab47bc;
    /* border: 3px solid #3f51b5;
    background: #3f51b5; */
    border-radius: 5px;
    height: 50px;
    width: 50px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: height 0.5s, width 0.5s, box-shadow 0.5s, border-color 0.5s,
      background-color 0.5s;
    overflow: hidden;

    svg {
      color: #fff;
      width: 30px;
      height: 30px;
      transition: width 0.5s, height 0.5s, color 0.5s;
    }
    & + button {
      margin: 0 0 0 30px;
    }

    &:hover {
      box-shadow: 0px 0px 15px 2px rgba(249, 168, 37, 1);
      background-color: #781489;
      border-color: #781489;
      svg {
        color: #ffff8d;
      }
    }
  }
`;

export const LogoLike = styled.div`
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);

  display: flex;

  color: #4a148c;
  text-shadow: 1px 5px 5px rgba(149, 117, 205, 1);

  p {
    font-size: 50px;
    font-family: 'Gugi', cursive;
    & + p {
      margin: 0 0 0 25px;
    }
  }
`;

export const LG = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background: #fff;

  /* width: 100vw; */
  height: 100%;
  button {
    background: transparent;
    border: 0;
    height: 100%;

    transition: filter 0.35s;

    &:hover {
      /* filter: brightness(1.4); */
      /* filter: drop-shadow(0px 0px 5px #4a148c); */
    }
    > div {
      position: relative;
      filter: blur(0px);
      height: 90%;
      display: flex;

      div {
        overflow: hidden;
        margin: auto 0;
        height: 100%;
        width: 125px;
        display: flex;
        justify-content: center;

        &:nth-child(2) {
          width: 0px;
          margin: auto 0;
        }
      }
    }
  }

  /* > div {
    position: relative;
    filter: blur(0px);
    height: 90%;
    display: flex;

    div {
      overflow: hidden;
      margin: auto 0;
      height: 100%;
      width: 125px;
      display: flex;
      justify-content: center;

      &:nth-child(2) {
        width: 0px;
        margin: auto 0;
      }
    }
  } */
`;

export const LS = styled.div`
  align-items: center;
  position: relative;

  overflow: hidden;
  border: 0px solid black;
  color: #4a148c;
  text-shadow: 1px 5px 5px rgba(149, 117, 205, 1);

  &:after {
    content: '';

    opacity: 1;
    height: 75%;

    position: absolute;
    right: 0;
    border: 0px solid #332e2e;
    border-right-width: 3px;
  }

  p {
    font-size: 50px;
    font-family: 'Gugi', cursive;
  }
`;

export const LF = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
  width: 160px;
  min-width: 160px;
  overflow: hidden;

  color: #4a148c;

  &:after {
    content: '';
    border: 0px solid black;
    border-right-width: 3px;
    position: absolute;
    height: 75%;
    right: 50%;
    height: 45%;

    opacity: 0;
  }

  p {
    font-size: 16px;
  }
`;

export const EndSession = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0px 5px;

  button {
    background: transparent;
    height: 50px;
    width: 50px;
    border: 0;

    svg {
      width: 30px;
      height: 30px;
      color: #781489;
      /* color: #0e2449; */
    }
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  p,
  strong {
    font-size: 16px;
  }

  > div {
    width: 100%;
    position: relative;
    display: flex;

    span {
      display: flex;
      position: relative;
      & + span {
        margin: 0 0 0 10px;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: -5px;
          transform: translateY(-50%);

          border: 1px solid #c6c6c6;
          height: 50%;
        }
      }
    }
    & + div {
      margin: 10px 0 0 0;
    }
  }
`;
