import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppData } from 'hooks/appData';

const PrivateRoute: React.FC = () => {
  const { appData } = useAppData();

  return appData ? <Outlet /> : <Navigate to={{ pathname: '/' }} />;
};

export default PrivateRoute;
