export function removeAcento(text: string): string {
  let code = text;
  code = code.replace(/[ÁÀÃÂ]/gi, 'a');
  code = code.replace(/[ÉÈÊ]/gi, 'e');
  code = code.replace(/[ÍÌÎ]/gi, 'i');
  code = code.replace(/[ÓÒÔÕ]/gi, 'o');
  code = code.replace(/[ÚÙÛÜ]/gi, 'u');
  code = code.replace(/[Ç]/gi, 'c');

  return code;
}

export function removeAcentoAndLower(text: string): string {
  let code = text;
  code = code.replace(/[ÁÀÃÂ]/gi, 'a');
  code = code.replace(/[ÉÈÊ]/gi, 'e');
  code = code.replace(/[ÍÌÎ]/gi, 'i');
  code = code.replace(/[ÓÒÔÕ]/gi, 'o');
  code = code.replace(/[ÚÙÛÜ]/gi, 'u');
  code = code.replace(/[Ç]/gi, 'c');

  return code.toLowerCase();
}
