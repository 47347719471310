import React from 'react';
import { HeaderContainer } from './styles';

const Footer: React.FC = () => {
  return (
    <HeaderContainer>
      {/*  */}
      {/*  */}
    </HeaderContainer>
  );
};

export default Footer;
