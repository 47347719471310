import React, { useCallback, useEffect, useState, useMemo } from 'react';

import Header from 'components/Header';
import Footer from 'components/Footer';

import { useAppData, AppDataProps } from 'hooks/appData';

import { Container, Content } from 'styles/wrappers';

import { CurrenciesContainer, CurrencyButton } from 'pages/Dashboard/styles';

import { ArrayProps, removeDups } from 'utils/handleArray';
import { removeAcentoAndLower } from 'utils/handleText';

import { Chart } from 'react-google-charts';

import { useSpring } from 'react-spring';

import { FaTimes } from 'react-icons/fa';
import {
  AnalysisContainer,
  CategoriesContainer,
  ClearContainer,
  ChartContainer,
  CategoryButton,
  MonthSelection,
  ChartExhibition,
  MonthButton,
} from './styles';

interface CategoriesProps extends ArrayProps {
  appear: boolean;
}

interface DatesProps extends CategoriesProps {
  month: number;
  year: number;
  gettime: number;
}

const Charts: React.FC = () => {
  const { appData, currency, currencies, updateCurrency, dates, handleDatefy } =
    useAppData();

  const [selectedDates, setSelectedDates] = useState<DatesProps[]>(() => {
    return dates
      .map((item) => ({
        ...item,
        month: handleDatefy(item.id).getMonth(),
        year: handleDatefy(item.id).getFullYear(),
        gettime: handleDatefy(item.id).getTime(),
        appear: false,
      }))
      .sort((x: DatesProps, y: DatesProps) =>
        x.gettime > y.gettime ? 1 : y.gettime > x.gettime ? -1 : 0,
      );
  });
  const [cats, setCats] = useState<CategoriesProps[]>(() =>
    removeDups(
      appData
        .filter(
          (item) =>
            item.money === (currency === 'all' ? currencies[1].id : currency),
        )
        .map((item) => ({ ...item })),
      'category',
    )
      .map((item) => ({
        id: item.category as string,
        label: item.category as string,
        appear: false,
      }))
      .sort((x, y) =>
        removeAcentoAndLower(x.label) > removeAcentoAndLower(y.label)
          ? 1
          : removeAcentoAndLower(y.label) > removeAcentoAndLower(x.label)
          ? -1
          : 0,
      ),
  );

  const handleCurrency = useCallback(
    (id) => {
      updateCurrency(id);
      setSelectedDates((state) =>
        state.map((item) => ({ ...item, appear: false })),
      );
      setCats(
        removeDups(
          appData
            .filter((item) => item.money === id)
            .map((item) => ({ ...item })),
          'category',
        )
          .map((item) => ({
            id: item.category as string,
            label: item.category as string,
            appear: false,
          }))
          .sort((x, y) =>
            removeAcentoAndLower(x.label) > removeAcentoAndLower(y.label)
              ? 1
              : removeAcentoAndLower(y.label) > removeAcentoAndLower(x.label)
              ? -1
              : 0,
          ),
      );
    },
    [appData, updateCurrency],
  );

  const handleAppearance = useCallback((id: string) => {
    setCats((state) => {
      const index = state.findIndex((item) => item.id === id);

      return state.map((item) =>
        item.id === id
          ? { ...state[index], appear: !state[index].appear }
          : item,
      );
    });
  }, []);

  const handleDateFilter = useCallback((id: string) => {
    setSelectedDates((state) => {
      const index = state.findIndex((item) => item.id === id);

      return state.map((item) =>
        item.id === id
          ? { ...state[index], appear: !state[index].appear }
          : item,
      );
    });
  }, []);

  const handleNumber = useCallback((number: number) => {
    return number * (number >= 0 ? 1 : -1);
  }, []);

  const handleClearFilters = useCallback(() => {
    setSelectedDates((state) =>
      state.map((item) => ({ ...item, appear: false })),
    );

    setCats((state) => state.map((item) => ({ ...item, appear: false })));
  }, []);

  const qteCats = useMemo(() => {
    return cats.reduce((accum, { appear }) => {
      return appear ? accum + 1 : accum;
    }, 0);
  }, [cats]);

  const base = useMemo<(string | number)[]>(() => {
    return selectedDates.filter((item) => !!item.appear).length > 0
      ? [
          'Categoria',
          ...selectedDates
            .filter((item) => !!item.appear)
            .map((item) => item.label),
          'Estimado',
        ]
      : ['Categoria', 'Pendente', 'Gasto', 'Estimado'];
  }, [selectedDates]);

  const selectedCats = useMemo(() => {
    return selectedDates.filter((item) => !!item.appear).length > 0
      ? qteCats > 0
        ? [
            ...cats
              .filter((item) => !!item.appear)
              .map((item) => [
                item.label,
                ...selectedDates
                  .filter((xItem) => !!xItem.appear)
                  .map((xItem, index) =>
                    handleNumber(
                      appData.reduce(
                        (
                          accum,
                          { money, category, status, value, month, year },
                        ) => {
                          return item.id === category &&
                            ['Ok'].indexOf(status) > -1 &&
                            money ===
                              (currency === 'all'
                                ? currencies[1].id
                                : currency) &&
                            year === xItem.year &&
                            month === xItem.month
                            ? accum + value
                            : accum;
                        },
                        0,
                      ),
                    ),
                  ),
                handleNumber(
                  appData.reduce(
                    (accum, { money, category, status, value }) => {
                      return item.id === category &&
                        ['Estimado'].indexOf(status) > -1 &&
                        money ===
                          (currency === 'all' ? currencies[1].id : currency)
                        ? accum + value
                        : accum;
                    },
                    0,
                  ),
                ),
              ]),
          ]
        : [
            [
              'Selecione uma categoria para continuar',
              ...selectedDates.filter((item) => !!item.appear).map(() => 0),
              0,
            ],
          ]
      : qteCats > 0
      ? [
          ...cats
            .filter((item) => !!item.appear)
            .map((item) => [
              item.label,
              handleNumber(
                appData.reduce((accum, { money, category, status, value }) => {
                  return item.id === category &&
                    ['Pendente'].indexOf(status) > -1 &&
                    money === (currency === 'all' ? currencies[1].id : currency)
                    ? accum + value
                    : accum;
                }, 0),
              ),
              handleNumber(
                appData.reduce((accum, { money, category, status, value }) => {
                  return item.id === category &&
                    ['Ok'].indexOf(status) > -1 &&
                    money === (currency === 'all' ? currencies[1].id : currency)
                    ? accum + value
                    : accum;
                }, 0),
              ),
              handleNumber(
                appData.reduce((accum, { money, category, status, value }) => {
                  return item.id === category &&
                    ['Estimado'].indexOf(status) > -1 &&
                    money === (currency === 'all' ? currencies[1].id : currency)
                    ? accum + value
                    : accum;
                }, 0),
              ),
            ]),
        ]
      : [['Selecione uma categoria para continuar', 0, 0, 0]];
    // : ;
  }, [
    appData,
    cats,
    currencies,
    currency,
    handleNumber,
    qteCats,
    selectedDates,
  ]);

  const chartData = useMemo(() => {
    return [[...base], ...selectedCats];
  }, [base, selectedCats]);

  const showClear = useSpring(
    cats.reduce((accum, { appear }) => {
      return appear ? accum + 1 : accum;
    }, 0) > 0 ||
      selectedDates.reduce((accum, { appear }) => {
        return appear ? accum + 1 : accum;
      }, 0) > 0
      ? {
          opacity: 1,
          height: '40px',
        }
      : { opacity: 0, height: '0px' },
  );

  return (
    <Container>
      <Header>{/* */}</Header>
      <Content>
        <CurrenciesContainer>
          {currencies.map((item, index) =>
            item.id !== 'all' ? (
              <span key={item.id}>
                <CurrencyButton
                  type="button"
                  disabled={
                    currency === 'all' ? index === 1 : item.id === currency
                  }
                  marked={
                    currency === 'all' ? index === 1 : item.id === currency
                  }
                  onClick={() => handleCurrency(item.id)}
                >
                  <p>{item.id.replace(/R/g, '')}</p>
                </CurrencyButton>
              </span>
            ) : null,
          )}
        </CurrenciesContainer>

        <AnalysisContainer>
          <CategoriesContainer>
            <ClearContainer style={showClear}>
              <button type="button" onClick={handleClearFilters}>
                Limpar
              </button>
            </ClearContainer>
            <div>
              {cats.map((item) => (
                <CategoryButton
                  key={item.id}
                  type="button"
                  marked={!(item.appear === false)}
                  onClick={() => handleAppearance(item.id)}
                >
                  <p>{item.label}</p>
                </CategoryButton>
              ))}
            </div>
          </CategoriesContainer>
          <ChartContainer>
            <MonthSelection>
              {selectedDates.map((item) => (
                <MonthButton
                  key={item.id}
                  type="button"
                  onClick={() => handleDateFilter(item.id)}
                  marked={!(item.appear === false)}
                >
                  {item.label}
                </MonthButton>
              ))}
            </MonthSelection>
            <ChartExhibition>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="100%"
                options={{
                  title: `${
                    currencies[
                      currencies.findIndex(
                        (item) =>
                          item.id ===
                          (currency === 'all' ? currencies[1].id : currency),
                      )
                    ].label
                  }`,

                  vAxes: {
                    0: {
                      format: `${
                        currency === 'all' ? currencies[1].id : currency
                      } `,
                    },
                  },
                  // colors: ['#F8BBD0', '#AB47BC', '#FFF59D'],
                }}
                data={chartData}
              />
            </ChartExhibition>
          </ChartContainer>
        </AnalysisContainer>
      </Content>
      <Footer />
    </Container>
  );
};

export default Charts;
